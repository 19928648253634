import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TextField, Menu, fade } from '@material-ui/core';
import cx from 'classnames';
import useEventListener from 'hooks/useEventListener';
import CustomMenuItem from 'components/Shared/CustomMenuItem';
import LoadingWrapper from './LoadingWrapper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';

const MENU_OFFSET_TOP = 16;

const useStyles = makeStyles((theme) => ({
  textField: {
    minWidth: 120,
  },
  select: {
    gap: 18,
    fontSize: 15,
    color: theme.palette.common.white,
    backgroundColor: fade(theme.palette.common.white, 0.3),
  },
  icon: {
    color: theme.palette.common.white,
    top: 'initial',
    right: 5,
    position: 'relative',
    width: 30,
  },
  endAdornment: {
    top: 'initial',
    right: 12,
    position: 'absolute',
    padding: 0,
    color: theme.palette.common.white,
    width: 'auto',
    height: 24,
    transition: 'transform 0.2s ease-out',
  },
  root: {
    '& $notchedOutline': {
      border: 'none',
    },
    '&:hover $notchedOutline': {
      border: 'none',
    },
    '&$focused $notchedOutline': {
      border: 'none',
    },
  },
  disabled: {
    '& .MuiSelect-icon.Mui-disabled': {
      color: theme.palette.common.white,
      opacity: .5,
    },
  },
  notchedOutline: {},
  paper: {
    overflowX: 'unset',
    overflowY: 'unset',
    paddingBottom: theme.spacing(1),
  },
  title: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    fontSize: 14,
  },
  loading: {
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    height: '100%',

    '& .MuiCircularProgress-colorPrimary': {
      color: theme.palette.common.white,
    },
  },
  whiteLoader: {
    color: theme.colors.white,
  },
}));

export const CUSTOM_MENU_TYPES = {
  MENU: 'menu',
  SELECT: 'select',
};

const CustomMenu = ({
                      type,
                      title,
                      children,
                      containerClassName,
                      selectClassName,
                      customSelectStyle,
                      titleClassName,
                      reCalcDimensionsTrigger,
                      offsetTop,
                      isLoading,
                      ...restProps
                    }) => {
  const [dimensions, setDimensions] = useState(null);
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const [recalculateTrigger, setRecalculateTrigger] = useState(false);
  const { isSideMenuPinned } = useSelector((state) => state.appSettings);
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      setRecalculateTrigger(!recalculateTrigger);
    }, 500);
  }, [isSideMenuPinned]);


  // recalculate dimensions on resize or on outside trigger changed
  const setRef = useCallback(
    (node) => {
      if (node !== null) {
        setDimensions(node.getBoundingClientRect());
      }
    },
    [windowInnerWidth, reCalcDimensionsTrigger, recalculateTrigger],
  );

  const handleResize = () => {
    setWindowInnerWidth(window.innerWidth);
  };

  useEventListener('resize', handleResize);

  const menuProps = {
    anchorReference: 'anchorPosition',
    anchorPosition: {
      top: dimensions ? dimensions.bottom + offsetTop : 0,
      left: dimensions ? dimensions.left + dimensions.width / 2 : 0,
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    MenuListProps: {
      disablePadding: true,
    },
    classes: {
      paper: classes.paper,
    },
  };

  const titleItem = (
    <CustomMenuItem
      disabled
      className={cx(classes.title, titleClassName)}
      style={{ opacity: 1 }}
    >
      {title}
    </CustomMenuItem>
  );

  return (
    <div ref={setRef} className={containerClassName}>
      {type === CUSTOM_MENU_TYPES.SELECT && (
        <div style={{ position: 'relative', display: 'flex' }}>
          <TextField
            select
            className={cx(classes.textField, selectClassName)}
            InputProps={{
              classes: {
                root: classes.root,
                disabled: classes.disabled,
                notchedOutline: classes.notchedOutline,
              },
            }}
            SelectProps={{
              MenuProps: { ...menuProps },
              SelectDisplayProps: {
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingRight: 46,
                  paddingLeft: 16,
                  backgroundColor: 'transparent',
                },
              },
              className: customSelectStyle || classes.select,
              classes: {
                icon: classes.endAdornment,
                isOpen: classes.isOpen,
              },
              IconComponent: ExpandMoreIcon,
            }}
            variant='outlined'
            {...restProps}
          >
            {titleItem}
            {children}
          </TextField>
          {
            <div
              style={{
                display: isLoading ? 'inherit' : 'none',
              }}
              className={classes.loading}
            >
              <LoadingWrapper
                isLoading={isLoading}
                loaderColor={'primary'}
              />
            </div>
          }
        </div>
      )}

      {type === CUSTOM_MENU_TYPES.MENU && (
        <Menu {...menuProps} {...restProps}>
          {titleItem}
          {children}
        </Menu>
      )}
    </div>
  );
};

CustomMenu.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  offsetTop: PropTypes.number,
  containerClassName: PropTypes.string,
  selectClassName: PropTypes.string,
  customSelectStyle: PropTypes.string,
  titleClassName: PropTypes.string,
  reCalcDimensionsTrigger: PropTypes.string,
  isLoading: PropTypes.bool,
};

CustomMenu.defaultProps = {
  containerClassName: undefined,
  selectClassName: undefined,
  customSelectStyle: undefined,
  titleClassName: undefined,
  reCalcDimensionsTrigger: '',
  offsetTop: MENU_OFFSET_TOP,
  isLoading: false,
};

export default CustomMenu;
