import React, { useEffect, useMemo, useState } from 'react';
import useFetchList from 'hooks/useFetchList';
import { Box, makeStyles } from '@material-ui/core';
import Modal from '../../../../../Shared/Modal';
import VirtualizedTable from '../../../../../Table/VirtualizedTable';
import TablePlaceholder from '../../../../../Shared/TablePlaceholder';
import accountsPlaceholderImage from 'assets/placeholders/accounts.svg';
import { useSelector } from 'react-redux';
import getFakeDataList from '../../../../../Shared/FakeDataGenerator';
import { FAKE_ASSESSMENT } from '../../../../../Assessments/constants';
import { SortDirection } from 'react-virtualized';
import { ALLOWED_ACCOUNT_COLUMNS, ALLOWED_ACCOUNT_FIELDS } from '../../../../constants';
import { TABLE_KEYS } from '../../../../../../redux_store/reducer/reducers/tableColumnsReducer';
import { getLocalizedLabel } from '../../../../../../helpers/localize';
import { CUSTOM_TAGS_OPTIONS } from '../../../../../Shared/CustomTags';
import { getTransitionWithVariable } from '../../../../../../redux_store/reducer/reducers/i18nReducer';
import AllowedAccountsModalFooter from './AllowedAccountsModalFooter';
import AllowedAccountsModalHeader from './AllowedAccountsModalHeader';
import debounce from 'lodash/debounce';

const useStyles = makeStyles((theme) => ({
  modal: {
    maxWidth: 1000,
  },
  tableContainer: {
    height: 500,
    width: 750,
    padding: 10,
    paddingTop: 0,
    borderRadius: 10,
    border: '1px solid rgba(0, 0, 0, 0.07)',
  },
}));


const AllowedAccountsSectionModal = ({
                                       values,
                                       changeAllowedAccounts,
                                       toggleAllowedAccountsModal,
                                       openAllowedAccountsModal,
                                     }) => {
  const { en: { titles, placeholders } } = useSelector((state) => state.i18n);
  const classes = useStyles();
  const [uuidsForSending, setUuidsForSending] = useState({
    includedAccountsUUIDs: [],
    excludedAccountsUUIDs: [],
  });
  const [includedAccountsUUIDs, setIncludedAccountsUUIDs] = useState([]);
  const [excludedAccountsUUIDs, setExcludedAccountsUUIDs] = useState([]);
  const [prevCheckboxesState, setPrevCheckboxesState] = useState(null);
  const { metadata: { currentAccountPermissions } } = useSelector((state) => state.user);

  useEffect(() => {
    if (values?.allowedAccounts) {
      setIncludedAccountsUUIDs(values?.allowedAccounts ?? []);
    }
  }, [values?.allowedAccounts]);

  const baseEndpoint = `/allowedAccounts/users/${values?.userUUID ?? 'empty'}`;
  const {
    items,
    totalAccountsCount,
    totalCount,
    completionRate,
    filter,
    loading: dataLoading,

    onLoadMoreRows,
    fetchAllItems,
    onSort,
    clearFilter,
    onFilter,
    onMultiColumnFilter,
    reloadData,
    fetchExportItems,

    ...restFetchProps
  } = useFetchList({
    baseEndpoint,
    baseParams: {
      fields: ALLOWED_ACCOUNT_FIELDS.join(),
      q: undefined,
    },
    baseSort: {
      sortBy: 'businessName',
      sortDirection: SortDirection.DESC,
    },
    baseFilter: {},
    customMethod: 'POST',
    patches: {
      'includedAccountsUUIDs': uuidsForSending.includedAccountsUUIDs,
      'excludedAccountsUUIDs': uuidsForSending.excludedAccountsUUIDs,
    },
  });

  const FAKE_ASSESSMENT_LIST = getFakeDataList(FAKE_ASSESSMENT);

  const updateUuidsInEndpoint = (f) => (...props) => {
    setUuidsForSending({
      includedAccountsUUIDs,
      excludedAccountsUUIDs,
    });
    f(...props);
  };

  const updateUuids = (newValue) => {
    setIncludedAccountsUUIDs(newValue.includedAccountsUUIDs);
    setExcludedAccountsUUIDs(newValue.excludedAccountsUUIDs);
    setUuidsForSending(newValue);
  };

  const closeModal = () => {
    toggleAllowedAccountsModal(false);
    setIncludedAccountsUUIDs(values?.allowedAccounts ?? []);
    setExcludedAccountsUUIDs([]);
    setPrevCheckboxesState(null);
  };

  const onRowClick = (props) => {
    let newIncludedAccounts = includedAccountsUUIDs;
    let newExcludedAccounts = excludedAccountsUUIDs;
    if (includedAccountsUUIDs.includes(props.uuid)) {
      newIncludedAccounts = newIncludedAccounts.filter(item => item !== props.uuid);
      newExcludedAccounts = [...newExcludedAccounts, props.uuid];
    } else {
      newIncludedAccounts = [...newIncludedAccounts, props.uuid];
      newExcludedAccounts = newExcludedAccounts.filter(item => item !== props.uuid);
    }
    setIncludedAccountsUUIDs(newIncludedAccounts);
    setExcludedAccountsUUIDs(newExcludedAccounts);
    setPrevCheckboxesState(null);
  };

  const accounts = useMemo(
    () => items &&
      items.map((account) => {
        return {
          ...account,
          customTags: account.customTags ? account.customTags.map(item => getLocalizedLabel(
            CUSTOM_TAGS_OPTIONS,
            item,
          )) : [],
          isAllowed: includedAccountsUUIDs.includes(account.uuid),
        };
      }),
    [items, includedAccountsUUIDs],
  );

  // prevent loading data after each row click
  const _onLoadMoreRows = updateUuidsInEndpoint(onLoadMoreRows);
  const _fetchAllItems = updateUuidsInEndpoint(fetchAllItems);
  const _onSort = updateUuidsInEndpoint(onSort);
  const _clearFilter = updateUuidsInEndpoint(clearFilter);
  const _reloadData = updateUuidsInEndpoint(reloadData);
  const _onFilter = debounce(updateUuidsInEndpoint(onFilter), 500);
  const _onMultiColumnFilter = debounce(updateUuidsInEndpoint(onMultiColumnFilter), 500);
  const _fetchExportItems = updateUuidsInEndpoint(fetchExportItems);

  return (<>
      <Modal
        open={openAllowedAccountsModal}
        classes={{ paper: classes.modal }}
        title={titles.allowedAccounts}
        handleClose={closeModal}
      >
        <Box p={3} display='flex' flexDirection={'column'}>
          <AllowedAccountsModalHeader
            accounts={accounts}
            includedAccountsUUIDs={includedAccountsUUIDs}
            excludedAccountsUUIDs={excludedAccountsUUIDs}
            prevCheckboxesState={prevCheckboxesState}
            setPrevCheckboxesState={setPrevCheckboxesState}
            updateUuids={updateUuids}
          />
          <div className={classes.tableContainer}>
            <VirtualizedTable
              tableKey={TABLE_KEYS.allowedAccounts}
              fakeRows={FAKE_ASSESSMENT_LIST}
              rowCount={totalCount ?? 0}
              rows={accounts ?? []}
              columns={ALLOWED_ACCOUNT_COLUMNS}
              onRowClick={onRowClick}
              FetchProps={{
                filter,
                onLoadMoreRows: _onLoadMoreRows,
                fetchAllItems: _fetchAllItems,
                onSort: _onSort,
                clearFilter: _clearFilter,
                onFilter: _onFilter,
                reloadData: _reloadData,
                onMultiColumnFilter: _onMultiColumnFilter,
                fetchExportItems: _fetchExportItems,
                ...restFetchProps,
              }}
              hideContextMenu={true}
              currentAccountPermissions={currentAccountPermissions}
              onDataLoading={!items || dataLoading}
              placeholder={
                <TablePlaceholder
                  image={accountsPlaceholderImage}
                  itemsNotFoundText={getTransitionWithVariable(placeholders.noItemsFound, {
                    item: titles.accounts,
                  })}
                  message={getTransitionWithVariable(placeholders.reviseFiltersOrCreateYourFirstItem, {
                    item: titles.accounts,
                  })}
                />
              }
            />
          </div>
          <AllowedAccountsModalFooter
            applyChanges={() => {
              changeAllowedAccounts(includedAccountsUUIDs);
              closeModal();
            }}
            closeModal={closeModal}
          />
        </Box>
      </Modal>
    </>
  );
};

export default AllowedAccountsSectionModal;
