import i18next from 'i18next';
import React from 'react';
import { ReactComponent as PendingIcon } from '../../assets/icons/pending.svg';
import { ReactComponent as ProcessingIcon } from '../../assets/icons/processing.svg';
import { ReactComponent as HoldIcon } from '../../assets/icons/hold.svg';
import permissions from '../../config/permissions';
import FILTER_TYPES from '../Filters/constants';
import Article from '../../assets/icons/Article.svg';
import Podcast from '../../assets/icons/Podcast.svg';
import Video from '../../assets/icons/Video.svg';
import { CUSTOM_TAGS_OPTIONS } from '../Shared/CustomTags';

export const FIELDS = [
  'accountCustomTags',
  'accountBusinessName',
  'accountCustomIdentifier',
  'patientFirstName',
  'patientLastName',
  'dateOfBirth',
  'orderTimestamp',
  'resourceName',
  'status',
  'invitedTimestamp',
  'patientMrnNumber',
  'createdBy',
  'appointmentTimestamp',
  'engagedTimestamp',
];

export const RESOURCE_FOR_PATIENT_FIELDS = [
  'orderTimestamp',
  'resourceName',
  'status',
  'invitedTimestamp',
  'patientMrnNumber',
  'createdBy',
  'appointmentTimestamp',
  'engagedTimestamp',
];

export const RESOURCE_STATUSES = {
  invited: 'invited',
  engaged: 'engaged',
  scheduled: 'scheduled',
};

export const RESOURCE_STATUSES_OPTIONS = [
  {
    label: i18next.t('tables:invited'),
    value: RESOURCE_STATUSES.invited,
    icon: HoldIcon,
    allowEditOrder: false,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:engaged'),
    value: RESOURCE_STATUSES.engaged,
    icon: PendingIcon,
    allowEditOrder: false,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:scheduled'),
    value: RESOURCE_STATUSES.scheduled,
    icon: ProcessingIcon,
    allowEditOrder: false,
    enabledForNonSponsors: false,
  },
];

export const GET_RESOURCES_COLUMNS = (isPatientPage, paramAccountUuid, totalAccountsCount) => [
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:customTags'),
    dataKey: 'accountCustomTags',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: CUSTOM_TAGS_OPTIONS,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
  },
  {
    width: 220,
    label: i18next.t('tables:account'),
    dataKey: 'accountBusinessName',
    columnData: {
      isFilterable: true,
      totalAccountsCount,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
    flexGrow: 3,
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 120,
    label: i18next.t('tables:customId'),
    dataKey: 'accountCustomIdentifier',
    columnData: {
      isFilterable: true,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:mrnNumber'),
    dataKey: 'patientMrnNumber',
    columnData: {
      isFilterable: true,
    },
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:patientFirstName'),
    dataKey: 'patientFirstName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 3,
    hidden: isPatientPage,
  },
  {
    width: 200,
    label: i18next.t('tables:patientLastName'),
    dataKey: 'patientLastName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 3,
    hidden: isPatientPage,
  },
  {
    width: 150,
    label: i18next.t('tables:dateOfBirth'),
    dataKey: 'dateOfBirth',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
    hidden: isPatientPage,
  },
  {
    width: 300,
    label: i18next.t('tables:resource'),
    dataKey: 'resourceName',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
    flexGrow: 3,
  },
  {
    width: 170,
    label: i18next.t('tables:orderTimestamp'),
    dataKey: 'orderTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 200,
    label: i18next.t('tables:appointmentTimestamp'),
    dataKey: 'appointmentTimestamp',
    columnData: {
      shouldRange: true,
      hasNoMaxDate: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 160,
    label: i18next.t('tables:invitedTimestamp'),
    dataKey: 'invitedTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 160,
    label: i18next.t('tables:engagedTimestamp'),
    dataKey: 'engagedTimestamp',
    columnData: {
      shouldRange: true,
      hasNoMaxDate: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 200,
    label: i18next.t('tables:createdBy'),
    dataKey: 'createdBy',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
  },
  {
    width: 200,
    label: i18next.t('tables:status'),
    dataKey: 'status',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: RESOURCE_STATUSES_OPTIONS,
    },
  },
];

function objectMap(object, mapFn) {
  // eslint-disable-next-line func-names
  return Object.keys(object).reduce(function (result, key) {
    // eslint-disable-next-line no-param-reassign
    result[key] = mapFn(object[key]);
    return result;
  }, {});
}

const resourceIcons = {
  article: {
    image: <img alt="" src={Article} />,
    backgroundColor: 'rgba(236, 127, 1, .1)',
  },
  podcast: {
    image: <img alt="" src={Podcast} />,
    backgroundColor: 'rgba(132, 21, 188, .1)',
  },
  video: {
    image: <img alt="" src={Video} />,
    backgroundColor: 'rgba(249, 88, 135, .1)',
  },
};

export const RESOURCE_ICON_LIST = objectMap(
  resourceIcons,
  ({ backgroundColor, image }) => (
    <div
      style={{
        backgroundColor,
        padding: '10px',
        borderRadius: '50%',
        width: '38px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      {image}
    </div>
  ),
);

export const RESOURCE_ICON_LIST_FOR_TABLE = objectMap(
  resourceIcons,
  ({ backgroundColor, image }) => (
    <div
      style={{
        backgroundColor,
        padding: '4px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      {image}
    </div>
  ),
);

export const FAKE_RESOURCE = {
  accountCustomTags: [],
  uuid: '',
  accountUUID: '',
  patientUUID: '',
  accountBusinessName: '',
  accountCustomIdentifier: '',
  patientFirstName: '',
  patientLastName: '',
  patientPhone: '',
  patientEmail: '',
  resourceKey: '',
  resourceType: '',
  resourceName: '',
  status: '',
  dateOfBirth: '',
  orderTimestamp: '',
  title: '',
  url: '',
  description: '',
  codeType: '',
  resource: '',
  resourceStatus: '',
  claimable: false,
};
