import * as React from 'react';
import {
  IconButton,
  InputAdornment,
  InputBase,
  makeStyles,
} from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import cx from 'classnames';
import {
  checkIsValidDate,
  parseDate,
  parseDateToString,
  parsedOnBlurInput,
  parseStringToDate,
} from './helpers';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(() => ({
  popover: {
    width: 332,
    height: 332,
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  disabledField: {
    cursor: 'default',
  },
  paper: {
    width: '100%',
    height: '100%',
    maxWidth: 'fit-content',
    maxHeight: 'fit-content',
    display: 'flex',
  },
  inputRoot: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '&error': {
      color: 'red',
    },
  },
  input: {
    fontSize: 16,
    fontWeight: 400,
    marginLeft: 6,
  },
  inputSmallFontSize: {
    fontWeight: 400,
    fontSize: 13,
    padding: 0,
  },
  icon: {
    width: '1rem',
    height: '1rem',
    fontSize: 14,
    padding: 0,
    disableRipple: true,

    '& .MuiTouchRipple-root span': {
      backgroundColor: 'transparent !important',
    },
  },
  iconCalendar: {
    padding: 0,
    disableRipple: true,
    fontSize: 15,

    '& .MuiTouchRipple-root span': {
      backgroundColor: 'transparent !important',
    },
  },
  arrowIcon: {
    width: '1em',
    height: '1em',
    fontSize: '1.5rem',
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  dateRangeDelimiter: {
    margin: '0 10px',
  },
}));

function UsualInput({
                      date,
                      onChange,
                      handleClick,
                      handleClose,
                      disabled,
                      label,
                      shouldReduceFontSize,
                      shortView,
                      error,
                    }) {
  const [inputValue, setInputValue] = React.useState('');

  const classes = useStyles();

  const handleDateChange = (newDate) => {
    if (onChange !== null && onChange !== undefined) {
      onChange(newDate);
      handleClose(null);
    }

    if (newDate != null) {
      setInputValue(parseDateToString(newDate));
    } else {
      setInputValue('');
    }
  };

  function updateFilter(newDate) {
    if (newDate) {
      const isValidDate = checkIsValidDate(newDate);

      if (isValidDate) {
        handleDateChange(parseStringToDate(newDate));
      }
    }
  }

  React.useEffect(() => {
    if (date === '' || date === null) {
      setInputValue('');
    } else if (date) {
      setInputValue(parseDateToString(date));
    }
  }, [date]);

  return (
    <>
      <InputBase
        label={label}
        disabled={disabled}
        value={inputValue}
        error={error}
        helperText={error}
        onChange={(event) => {
          const newValue = parseDate(event.currentTarget.value, inputValue);
          setInputValue(newValue);
          updateFilter(newValue);
        }}
        onBlur={(event) => {
          const newValue = parsedOnBlurInput(event.currentTarget.value);
          if (newValue.toString() !== 'NaN/NaN/NaN') {
            setInputValue(newValue);
            const newDate = parseStringToDate(newValue);
            if (newDate.toString() !== 'Invalid Date') {
              handleDateChange(newDate);
            }
          } else {
            setInputValue('');
            handleDateChange(null);
          }
        }}
        onFocus={shortView ? (e) => {
          e.target.blur();
          e.preventDefault();
          e.stopPropagation();
          handleClick(e);
        } : null}
        placeholder={shortView ? '' : 'MM/DD/YYYY'}
        classes={{
          root: cx(classes.inputRoot, {
            [classes.pointerCursor]: shortView,
            [classes.disabledField]: disabled,
          }),
          input: cx(shouldReduceFontSize
            ? classes.inputSmallFontSize
            : classes.input, {
            [classes.pointerCursor]: shortView,
            [classes.disabledField]: disabled,
          }),
        }}
        autoComplete='off'
        startAdornment={
          <InputAdornment position='start'>
            <IconButton
              classes={{
                root: cx(classes.iconCalendar, {
                  [classes.disabled]: disabled,
                }),
              }}
              onClick={handleClick}
              disabled={disabled}
            >
              <TodayIcon
                color='primary'
                classes={{
                  root: cx(classes.iconCalendar, {
                    [classes.disabled]: disabled,
                  }),
                }}
              />
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          ((inputValue !== null && inputValue !== '') || shortView) ? (
            <InputAdornment position='end'>
              <IconButton
                disabled={disabled}
                classes={{
                  root: cx(classes.icon, {
                    [classes.disabled]: disabled,
                  }),
                }}
                onClick={() => {
                  setInputValue('');
                  handleDateChange(null);
                }}
              >
                {
                  shortView && !inputValue ?
                    <ArrowDropDownIcon
                      classes={{
                        root: cx(classes.icon, classes.arrowIcon, {
                          [classes.disabled]: disabled,
                        }),
                      }}
                    /> :
                    <CancelIcon
                      classes={{
                        root: cx(classes.icon, {
                          [classes.disabled]: disabled,
                        }),
                      }}
                    />
                }
              </IconButton>
            </InputAdornment>
          ) : null
        }
      />
    </>
  );
}

UsualInput.propTypes = {
  date: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  shouldReduceFontSize: PropTypes.bool,
  shortView: PropTypes.bool,
};

UsualInput.defaultProps = {
  date: null,
  onChange: undefined,
  label: undefined,
  shouldReduceFontSize: false,
  handleClose: undefined,
  handleClick: undefined,
  disabled: false,
  shortView: false,
};

export default UsualInput;
