import React, { useEffect } from 'react';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import TablePlaceholder from 'components/Shared/TablePlaceholder';
import VirtualizedTable from 'components/Table/VirtualizedTable';
import useFetchList from 'hooks/useFetchList';
import accountsPlaceholderImage from 'assets/placeholders/accounts.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/users.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useSelector } from 'react-redux';
import getFakeDataList, { FAKE_TITLE_DATA } from '../../Shared/FakeDataGenerator';
import { TABLE_KEYS } from '../../../redux_store/reducer/reducers/tableColumnsReducer';
import RefreshTableButton from '../../Shared/RefreshTableButton';
import { ALERT_ACTIONS, ALERT_COLUMNS, ALERT_FIELDS, ALERTS_TYPE, FAKE_ALERTS } from '../constants';
import { getTransitionWithVariable } from '../../../redux_store/reducer/reducers/i18nReducer';

const AlertList = () => {
  const { en: { titles, placeholders } } = useSelector((state) => state.i18n);
  const { tablePreFilters } = useSelector((state) => state.alerts);
  const { metadata: { currentAccountPermissions } } = useSelector((state) => state.user);

  const {
    items,
    totalCount,
    reloadData,
    clearFilter,
    onMultiColumnFilter,
    loading: dataLoading,
    ...restFetchProps
  } = useFetchList({
    baseEndpoint: '/alerts',
    baseParams: {
      fields: ALERT_FIELDS.join(),
    },
    baseFilter: tablePreFilters,
  });

  useEffect(() => {
    onMultiColumnFilter(tablePreFilters);
  }, [tablePreFilters]);

  const actions = () => [
    {
      key: '1',
      icon: UsersIcon,
      onActionClick: () => {
      },
      showConditionFlag: 'action',
      showConditionValue: ALERT_ACTIONS.acknowledge,
      children: titles.acknowledge,
    },
    {
      key: '2',
      icon: EditIcon,
      onActionClick: () => {
      },
      showConditionFlag: 'action',
      showConditionValue: ALERT_ACTIONS.oneMore,
      children: titles.oneMore,
    },
  ];

  const isTableDataLoading = dataLoading;

  const FAKE_ALERT_LIST = getFakeDataList(FAKE_ALERTS);
  return (
    <ContentWrapper
      titleText={titles.alerts}
      count={totalCount || FAKE_TITLE_DATA}
      actions={[
        <RefreshTableButton
          reloadData={() => {
          }}
          hasRightPadding
          isLoading={dataLoading}
        />,
      ]}
    >
      <VirtualizedTable
        tableKey={TABLE_KEYS.accounts}
        hasColumnsSelect={true}
        fakeRows={FAKE_ALERT_LIST}
        rowCount={totalCount ?? FAKE_DATA.length}
        rows={items ?? FAKE_DATA}
        columns={ALERT_COLUMNS}
        FetchProps={{ ...restFetchProps }}
        currentAccountPermissions={currentAccountPermissions}
        onDataLoading={isTableDataLoading}
        actionProps={{
          actions: actions(),
        }}
        placeholder={
          <TablePlaceholder
            image={accountsPlaceholderImage}
            itemsNotFoundText={getTransitionWithVariable(placeholders.noItemsFound, {
              item: titles.alerts,
            })}
            message={getTransitionWithVariable(placeholders.reviseFiltersOrCreateYourFirstItem, {
              item: titles.alerts,
            })}
          />
        }
      />
    </ContentWrapper>
  );
};

export default AlertList;

const FAKE_DATA = Array.apply(null, Array(50)).map((v, i) => {
  return {
    uuid: i + 100,
    name: `Some ${i + 1} name`,
    type: 3 < i && i < 6
      ? ALERTS_TYPE.nonCritical
      : i % 2 === 1 || i < 3
        ? ALERTS_TYPE.critical
        : ALERTS_TYPE.nonCritical,
    criticalCategory: `Critical Category ${i + 1}`,
    action: i % 2 === 1 ? ALERT_ACTIONS.acknowledge : ALERT_ACTIONS.oneMore,
    moreTitle: `Some ${i + 1} name`,
  };
});
