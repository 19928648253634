import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setAuthorizationExpiresTimeout } from '../redux_store/reducer/reducers/appSettingsReducer';
import client from '../config/fetch';
import { ClientContextProvider } from 'react-fetching-library';

function ClientProvider({ children }) {
  const dispatch = useDispatch();

  const updateAuthorizationExpires = (time) => {
    dispatch(setAuthorizationExpiresTimeout(time));
  }

  const wrappedClient = useMemo(
    (time) => client(updateAuthorizationExpires(time)),
    [],
  );

  return (
    <ClientContextProvider client={wrappedClient}>
      {children}
    </ClientContextProvider>
  );
}

ClientProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ClientProvider;
