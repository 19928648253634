import { createSlice } from '@reduxjs/toolkit';

// export const fetchAccounts = createAsyncThunk(
//   'accounts/fetchAccounts',
//   async () => {
//     return await client.get(`/accounts${qs.stringify({ fields: FIELDS.join()}, {
//       addQueryPrefix: true,
//     })}`);
//   },
// );

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState: {
    tablePreFilters: {},
  },
  reducers: {
    setAlertsPreFilter: (state, action) => {
      state.tablePreFilters = action.payload;
    },
  },
  // extraReducers(builder) {
  //   builder
  //     .addCase(fetchAccounts.pending, (state) => {
  //       state.status = REQUEST_STATUS.loading;
  //     })
  //     .addCase(fetchAccounts.fulfilled, (state, action) => {
  //       if (action.payload.ok) {
  //         state.status = REQUEST_STATUS.succeeded;
  //         state.alerts =
  //           action.payload.data.data.items.map((account) => {
  //             return { ...account,
  //               customIdentifier: account.customIdentifier
  //                 ? account.customIdentifier
  //                 : 'N/A',
  //               address: getLocalizedAddress(account.address),
  //               moreTitle: account.businessName,
  //             }});
  //       } else {
  //         state.status = REQUEST_STATUS.failed;
  //         state.error = action?.payload?.error?.message;
  //       }
  //     });
  // },
});

export const {
  setAlertsPreFilter,
} = alertsSlice.actions;

export default alertsSlice.reducer;
