import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import UserMenu from 'components/Navigation/UserMenu';
import AccountMenu from 'components/Navigation/AccountMenu';
import AccountSelector from 'components/Navigation/AccountSelector';
import { useSelector } from 'react-redux';
// import AlertSection from './widgets/AlertSection';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(3),
    minHeight: 80,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 10,
  },
  accountMenu: {
    marginLeft: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
  },
  userMenu: {
    marginLeft: 'auto',
  },
  displayFlex: {
    display: 'flex',
  },
  userMenuBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const TopBar = () => {
  const classes = useStyles();
  const { isSideMenuPinned } = useSelector((state) => state.appSettings);

  return (
    <Grid container alignItems='center' className={classes.root}
          style={{
            width: `calc(100% - ${isSideMenuPinned ? 240 : 80}px)`,
            transition: '0.3s',
          }}
    >
      <Grid item xs={6} className={classes.displayFlex}>
        <AccountSelector />
        <AccountMenu className={classes.accountMenu} />
      </Grid>
      <Grid item xs={6} className={classes.userMenuBox}>
        <Box display='flex' alignItems='center'>
          {/*<AlertSection/>*/}
          <UserMenu className={classes.userMenu} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default TopBar;
