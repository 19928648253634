import React, { useEffect, useState } from 'react';
import IdleDialog from 'components/Dialogs/IdleDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, logout } from '../../redux_store/reducer/reducers/userReducer/api';

const LOGOUT_TIMEOUT = 1000 * 60; //ms / s / m / h
let timeoutID;

const Idle = () => {
  const { authorizationExpiresTimeout } = useSelector((state) => state.appSettings);
  const { isLogin } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => setDialogOpen(false);

  const onStayLogin = () => {
    if (isLogin) {
      dispatch(getUser());
    }
    handleDialogClose(false);
  };

  const handleLogout = (text) => {
    const _isLogin = isLogin;
    dispatch(logout());
    if (_isLogin) {
      window.location.href = `/login${text}`;
    }
    handleDialogClose();
  };

  useEffect(() => {
    clearTimeout(timeoutID);

    if (authorizationExpiresTimeout) {
      const diff = authorizationExpiresTimeout - Date.now() - 1000;
      if (diff > 0) {
        timeoutID = setTimeout(() => {
          if (localStorage.getItem('isLogin')) {
            setDialogOpen(true);
          }
        }, diff - 1000);
      } else {
        handleLogout('#logout_without_notification');
      }
    }
  }, [authorizationExpiresTimeout]);

  return <IdleDialog
    open={isDialogOpen && isLogin}
    logout={handleLogout}
    onClose={onStayLogin}
    onLogout={handleLogout}
    logoutTimeout={LOGOUT_TIMEOUT / 1000}
  />;
};

export default Idle;
