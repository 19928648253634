import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Tooltip,
} from '@material-ui/core';
import cx from 'classnames';
import { AppRouteContext } from 'contexts';
import { alpha } from '@material-ui/core/styles';
import { checkPermission } from '../../../../helpers/checkPermissions';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  navItem: {
    padding: theme.spacing(1.5, 3),
    color: theme.colors.gray1,
    textTransform: 'uppercase',
    fontWeight: '500',
    overflow: 'hidden',

    '&:hover': {
      background: alpha(theme.palette.common.white, 0.3),
    },
  },
  navItemChild: {
    padding: '4px 1px 4px 30px',
    color: theme.palette.common.white,
    textTransform: 'none',
  },
  sideMenuPinnedChild: {
    transition: 'padding 0.3s ease-in, opacity 0.3s ease-in',
    paddingLeft: 14,
  },
  listItemText: {
    fontSize: 13,
    fontWeight: 'bold',
    letterSpacing: 2,
    opacity: 0.5,
    color: theme.palette.common.white,
    overflow: 'hidden',
    whiteSpace: 'normal',
    minWidth: 150,
  },
  selectedItemText: {
    transition: 'padding 0.5s ease-in, opacity 0.5s ease-in',
    opacity: 1,
  },
  sideMenuPinned: {
    transition: 'padding 0.5s ease-in, opacity 0.5s ease-in',
    opacity: 0,
  },
  selectedItem: {
    background: `linear-gradient(90deg, ${alpha(theme.colors.blue, 0)} 0%,
      ${theme.colors.blue3} 99%)`,
  },
  selectedChildItem: {
    background: `linear-gradient(90deg, ${alpha(theme.colors.blue, 0)} 10%,
      ${alpha(theme.colors.blue3, .6)} 99%)`,
  },
  simpleIcon: {
    margin: theme.spacing(0.5),
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: theme.colors.gray1,
  },
  navIcon: {
    width: 20,
    height: 20,
  },
  listItemIcon: {
    fill: theme.colors.gray1,
    minWidth: 40,
  },
}));

const NavItem = ({ item, isChild, disabled }) => {
  const classes = useStyles();
  const { isSideMenuPinned } = useSelector((state) => state.appSettings);
  const { setAppRoute, breadcrumbs } = useContext(AppRouteContext);
  const {
    metadata: { currentAccountPermissions },
  } = useSelector((state) => state.user);
  const { disabledFeatures } = useSelector((state) => state.appSettings);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleClick = () => {
    if (item.route.externalReference) {
      openInNewTab(item.route.path);
    } else {
      setAppRoute({
        path: item.route.path,
        title: item.route.additional.title,
        clearBreadcrumbs: true,
      });
    }
  };

  const selected = useMemo(
    () => ((breadcrumbs[0].title &&
      (breadcrumbs[0].title === item.name ||
        breadcrumbs[0].title === item.route.additional.title))),
    [breadcrumbs[0].title, item],
  );

  const childSelected = useMemo(
    () => (item.children && item.children.map((item) => item?.route?.additional?.title).includes(breadcrumbs[0].title)),
    [breadcrumbs[0].title, item],
  );

  const ItemRouteIcon = item.route && item.route.additional.icon;
  const icon = ItemRouteIcon ? (
    <ItemRouteIcon
      fill={(childSelected || selected) ? null : alpha('#FFF', 0.3)}
      className={classes.navIcon}
    />
  ) : (
    <div className={classes.simpleIcon} />
  );

  const _itemText = item.name || item.route.additional.title;

  return (
    <>
      <Tooltip
        disableFocusListener={isSideMenuPinned}
        disableHoverListener={isSideMenuPinned}
        disableTouchListener={isSideMenuPinned}
        title={_itemText}
        placement={'right'}
        style={{
          zIndex: 100,
        }}
      >
        <ListItem
          button
          className={cx(classes.navItem, {
            [classes.navItemChild]: isChild,
            [classes.selectedItem]: selected || childSelected,
            [classes.selectedChildItem]: isChild && selected,
            [classes.sideMenuPinnedChild]: isChild && !isSideMenuPinned,
          })}
          onClick={handleClick}
          disabled={disabled}
        >
          {!isChild && (
            <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
          )}
          <ListItemText
            primary={!isSideMenuPinned && isChild ? _itemText.substring(0, 1) : _itemText}
            primaryTypographyProps={{
              className: cx(classes.listItemText, {
                [classes.selectedItemText]: selected || childSelected,
                [classes.sideMenuPinned]: !isChild && !isSideMenuPinned,
              }),
            }}
          />
        </ListItem>
      </Tooltip>
      {item.children && (
        <List component='div' disablePadding>
          {item.children.map((childItem) => {
            const isAllowed =
              checkPermission(
                currentAccountPermissions,
                childItem.route.additional.permission,
              ) && !disabledFeatures.includes(childItem.route.additional.disabledId);

            return isAllowed ? <div style={{
              display: 'flex',
            }}>
              <div
                style={{
                  height: childItem.id === item.children[item.children.length - 1].id ? 22 : 35,
                  marginLeft: 34,
                  borderLeft: '1px solid rgba(240, 248, 255, 0.35)',
                }}
              />
              <NavItem key={childItem.id}
                       item={childItem}
                       isChild
              />
            </div> : null;
          })}
        </List>
      )}
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.shape({
    route: PropTypes.object,
    name: PropTypes.string,
    children: PropTypes.array,
  }).isRequired,
  isChild: PropTypes.bool,
  disabled: PropTypes.bool,
};

NavItem.defaultProps = {
  isChild: false,
  disabled: false,
};

export default NavItem;
