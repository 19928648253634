import { ASSESSMENT_STATUSES } from 'components/Assessments/constants';
import { ORDER_STATUSES } from '../components/Orders/constants';
import { BADGES_COLOR_TYPES } from '../components/Shared/Badge';
import { RESOURCE_STATUSES } from '../components/Resources/constants';
import {
  BILLING_CLAIMS_STATUSES,
  CLAIMS_STATUSES,
} from '../components/ClaimsReporting/constants';
import { CLAIM_REPORTS_STATUSES } from '../components/ClaimReport/constants';
import { CUSTOM_TAGS_STATUSES, SURVEY_TAGS_STATUSES } from '../components/Shared/CustomTags';
import { ALERTS_TYPE } from '../components/Alerts/constants';

const StatusColor = {
  [ORDER_STATUSES.shipped]: BADGES_COLOR_TYPES.green,
  [ORDER_STATUSES.delayed]: BADGES_COLOR_TYPES.grey,
  [ORDER_STATUSES.processing]: BADGES_COLOR_TYPES.lightBlue,
  [ORDER_STATUSES.onHold]: BADGES_COLOR_TYPES.grey,
  [ORDER_STATUSES.pending]: BADGES_COLOR_TYPES.blue,
  [ORDER_STATUSES.sending]: BADGES_COLOR_TYPES.lightBlue,
  [ORDER_STATUSES.canceled]: BADGES_COLOR_TYPES.grey,
  [ASSESSMENT_STATUSES.pendingReview]: BADGES_COLOR_TYPES.orange,
  [ASSESSMENT_STATUSES.processing]: BADGES_COLOR_TYPES.red,
  [ASSESSMENT_STATUSES.completed]: BADGES_COLOR_TYPES.green,
  [ASSESSMENT_STATUSES.inProgress]: BADGES_COLOR_TYPES.lightBlue,
  [ASSESSMENT_STATUSES.invited]: BADGES_COLOR_TYPES.grey,
  [ASSESSMENT_STATUSES.scheduled]: BADGES_COLOR_TYPES.grey,
  [ASSESSMENT_STATUSES.eTransfer]: BADGES_COLOR_TYPES.green2,
  [ASSESSMENT_STATUSES.canceled]: BADGES_COLOR_TYPES.grey,
  [RESOURCE_STATUSES.invited]: BADGES_COLOR_TYPES.grey,
  [RESOURCE_STATUSES.engaged]: BADGES_COLOR_TYPES.lightBlue,
  [RESOURCE_STATUSES.scheduled]: BADGES_COLOR_TYPES.grey,
  [CLAIMS_STATUSES.draft]: BADGES_COLOR_TYPES.grey,
  [CLAIMS_STATUSES.notCreated]: BADGES_COLOR_TYPES.lightBlue,
  [CLAIMS_STATUSES.completed]: BADGES_COLOR_TYPES.green,
  [BILLING_CLAIMS_STATUSES.billed]: BADGES_COLOR_TYPES.grey,
  [BILLING_CLAIMS_STATUSES.icdIssue]: BADGES_COLOR_TYPES.lightBlue,
  [BILLING_CLAIMS_STATUSES.insuranceIssue]: BADGES_COLOR_TYPES.grey,
  [BILLING_CLAIMS_STATUSES.appointmentCancelled]: BADGES_COLOR_TYPES.blue,
  [CLAIM_REPORTS_STATUSES.reported]: BADGES_COLOR_TYPES.green,
  [CLAIM_REPORTS_STATUSES.reportable]: BADGES_COLOR_TYPES.green2,

  [ALERTS_TYPE.critical]: BADGES_COLOR_TYPES.red,
  [ALERTS_TYPE.nonCritical]: BADGES_COLOR_TYPES.lightGrey2,
};

const getStatusBadge = (status) => StatusColor[status];

export default getStatusBadge;

const TagsColor = {
  [CUSTOM_TAGS_STATUSES['ISP']]: BADGES_COLOR_TYPES.green,
  [CUSTOM_TAGS_STATUSES['License']]: BADGES_COLOR_TYPES.lightBlue,
  [CUSTOM_TAGS_STATUSES['Orders Only']]: BADGES_COLOR_TYPES.blue,
  [CUSTOM_TAGS_STATUSES['Trial']]: BADGES_COLOR_TYPES.orange,
  [CUSTOM_TAGS_STATUSES['Demo']]: BADGES_COLOR_TYPES.red,
  [CUSTOM_TAGS_STATUSES['Other']]: BADGES_COLOR_TYPES.green2,
  [CUSTOM_TAGS_STATUSES['Inactive']]: BADGES_COLOR_TYPES.purple,

  [SURVEY_TAGS_STATUSES['hasClaim']]: BADGES_COLOR_TYPES.orange,
  [SURVEY_TAGS_STATUSES['hasProgram']]: BADGES_COLOR_TYPES.blue,
};

export const getTagsColor = (status) => TagsColor[status];
