import i18next from 'i18next';
import permissions from '../../config/permissions';
import FILTER_TYPES from '../Filters/constants';
import { CUSTOM_TAGS_OPTIONS } from '../Shared/CustomTags';
import { CLAIM_REPORTS_STATUSES_OPTIONS } from '../ClaimReport/constants';
import { currencyFormat } from '../Table/helpers';

export const FIELDS = [
  'accountCustomTags',
  'accountBusinessName',
  'accountCustomIdentifier',
  'patientFirstName',
  'patientLastName',
  'patientMrnNumber',
  'patientDateOfBirth',
  'entityName',
  'createdBy',
  'modifiedBy',
  'reportableGeneratedTimestamp',
  'entityOrderTimestamp',
  'entityCompletedTimestamp',
  'appointmentTimestamp',
  'paymentDate',
  'cptCode',
  'paid',
  'icd',
  'reportStatus',
  'dos',
];

export const REPORTABLES_FOR_PATIENT_FIELDS = [
  'accountCustomTags',
  'accountBusinessName',
  'accountCustomIdentifier',
  'entityName',
  'createdBy',
  'modifiedBy',
  'reportableGeneratedTimestamp',
  'entityOrderTimestamp',
  'entityCompletedTimestamp',
  'appointmentTimestamp',
  'paymentDate',
  'cptCode',
  'paid',
  'icd',
  'reportStatus',
  'dos',
];

export const GET_REPORTABLES_COLUMNS = (
  isPatientPage,
  paramAccountUuid,
  totalAccountsCount,
) => [
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:customTags'),
    dataKey: 'accountCustomTags',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: CUSTOM_TAGS_OPTIONS,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
  },
  {
    width: 220,
    label: i18next.t('tables:account'),
    dataKey: 'accountBusinessName',
    columnData: {
      isFilterable: true,
      totalAccountsCount,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
    flexGrow: 1,
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 120,
    label: i18next.t('tables:customId'),
    dataKey: 'accountCustomIdentifier',
    columnData: {
      isFilterable: true,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:patientFirstName'),
    dataKey: 'patientFirstName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 1,
    hidden: isPatientPage,
  },
  {
    width: 200,
    label: i18next.t('tables:patientLastName'),
    dataKey: 'patientLastName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 1,
    hidden: isPatientPage,
  },
  {
    width: 150,
    label: i18next.t('tables:mrnNumber'),
    dataKey: 'patientMrnNumber',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 1,
    hidden: isPatientPage,
  },
  {
    width: 150,
    label: i18next.t('tables:patientDOB'),
    dataKey: 'patientDateOfBirth',
    hidden: isPatientPage,
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 250,
    label: i18next.t('tables:entityName'),
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
    dataKey: 'entityName',
    flexGrow: 1,
  },
  {
    width: 150,
    label: i18next.t('tables:createdBy'),
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
    dataKey: 'createdBy',
    flexGrow: 1,
  },
  {
    width: 150,
    label: i18next.t('tables:modifiedBy'),
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
    dataKey: 'modifiedBy',
    flexGrow: 1,
  },
  {
    width: 260,
    label: i18next.t('tables:reportableGeneratedTimestamp'),
    dataKey: 'reportableGeneratedTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 200,
    label: i18next.t('tables:entityOrderTimestamp'),
    dataKey: 'entityOrderTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 200,
    label: i18next.t('tables:entityCompletedTimestamp'),
    dataKey: 'entityCompletedTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 200,
    label: i18next.t('tables:appointmentTimestamp'),
    dataKey: 'appointmentTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 200,
    label: i18next.t('tables:paymentDate'),
    dataKey: 'paymentDate',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 120,
    label: i18next.t('tables:cptCode'),
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
    dataKey: 'cptCode',
    flexGrow: 1,
  },
  {
    width: 150,
    label: i18next.t('tables:price'),
    columnData: {
      isFilterable: true,
      formattedFunction: currencyFormat,
      filterType: FILTER_TYPES.PRICE,
    },
    dataKey: 'price',
    flexGrow: 1,
  },
  {
    width: 150,
    label: i18next.t('tables:paid'),
    columnData: {
      isFilterable: true,
      formattedFunction: currencyFormat,
      filterType: FILTER_TYPES.PRICE,
    },
    dataKey: 'paid',
    flexGrow: 1,
  },
  {
    width: 250,
    label: i18next.t('tables:icd'),
    columnData: {
      isFilterable: true,
    },
    dataKey: 'icd',
    flexGrow: 1,
  },
  {
    width: isPatientPage ? 350 : 220,
    label: i18next.t('tables:reportStatus'),
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: CLAIM_REPORTS_STATUSES_OPTIONS,
    },
    dataKey: 'reportStatus',
    flexGrow: 1,
  },
  {
    width: 160,
    label: i18next.t('tables:dos'),
    dataKey: 'dos',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
];

export const FAKE_REPORTABLE = {
  accountCustomTags: [],
  uuid: '',
  patientUUID: '',
  accountCustomIdentifier: '',
  accountUUID: '',
  reportStatus: '',
  entityName: '',
  accountBusinessName: '',
  patientFirstName: '',
  patientLastName: '',
  patientDateOfBirth: '',
  entityOrderTimestamp: '',
  entityCompletedTimestamp: '',
  moreTitle: '',
  resendInvitationAction: true,
  disabledCheckbox: true,
  disabledRowMenu: true,
};
