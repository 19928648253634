import i18next from 'i18next';
import FILTER_TYPES from '../Filters/constants';

export const ALERTS_TYPE = {
  critical: 'Critical',
  nonCritical: 'Non-Critical',
}

export const ALERT_ACTIONS = {
  acknowledge: 'Acknowledge',
  oneMore: 'Some Action with modal',
}

export const TYPE_OPTIONS = [
  {
    label: i18next.t('tables:critical'),
    value: ALERTS_TYPE['critical'],
  },
  {
    label: i18next.t('tables:nonCritical'),
    value: ALERTS_TYPE['nonCritical'],
  },
];

export const ALERT_FIELDS = ['name', 'type', 'criticalCategory'];

export const FAKE_ALERTS = {
  name: '',
  type: ALERTS_TYPE.nonCritical,
  criticalCategory: '',
};

export const ALERT_COLUMNS = [
  {
    width: 200,
    label: i18next.t('tables:alertName'),
    dataKey: ALERT_FIELDS[0],
    columnData: {
      isFilterable: true,
    },
    flexGrow: 2,
  },
  {
    width: 300,
    label: i18next.t('tables:type'),
    dataKey: ALERT_FIELDS[1],
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: TYPE_OPTIONS,
      hasBubble: true,
    },
    flexGrow: 2,
  },
  {
    width: 200,
    label: i18next.t('tables:criticalCategory'),
    dataKey: ALERT_FIELDS[2],
    columnData: {
      isFilterable: true,
    },
    flexGrow: 2,
  },
];


